import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { Seo } from '@/layout/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const OurSafety: Page = () => (
    <Fragment>
        <Seo
            title="Safety Measures | Affordable Dental Office | Auburn"
            description="At Nuttall Smiles, your safety is our priority. Trust in the best dentist servicing in Auburn and surrounding areas. Request an appointment now."
        />
        <CommonHero img>
            <StaticImage
                src="../../../assets/images/about/our-safety.jpg"
                alt="stethoscope with mask"
                layout="fullWidth"
                loading="eager"
            />
        </CommonHero>
        <Article>
            <p>We’ve worked hard to ensure that you and our team are safe…</p>
            <ul>
                <li>
                    You have already seen some changes on your arrival as we take steps to create
                    SOCIAL DISTANCING.
                </li>
                <li>Visitors are not allowed in the clinic at this time.</li>
                <li>We are SCREENING every patient before they arrive.</li>
                <li>We ask all entering the office to SANITIZE their hands.</li>
                <li>We have and will ALWAYS use masks, fresh gloves, and clean glasses.</li>
                <li>We have two LARGE medical grade HEPA filters running.</li>
                <li>We have additional personal protection equipment (PPE).</li>
                <li>
                    We’ve removed all non-essential items from each operatory for GREATER
                    sterilization access.
                </li>
                <li>
                    We now have an ANTI-VIRAL pre-procedural rinse, recommended by the CDC, which
                    will be provided.
                </li>
                <li>
                    We also take our own temperatures daily, as a PRECAUTION, in addition to taking
                    yours.
                </li>
                <li>We will be using high-vacuum suction devices to REDUCE aerosols.</li>
                <li>Our disinfectant KILLS the virus in 30 seconds.</li>
                <li>
                    We are CONSTANTLY wiping down any high-touch surface — doorknobs, countertops,
                    light switches, pens, drawer pulls, etc.
                </li>
                <li>Every instrument goes through a detailed STERILIZATION process.</li>
                <li>
                    EVERY week, we run a spore test on our sterilizer, testing for the ability to{' '}
                    <b>kill the toughest</b>.
                </li>
                <li>
                    Please know we have always STRICTLY followed state health guidelines — but this
                    is a new virus requiring new safety measures, and we are incorporating
                    heightened sterilization protocols in response to this threat.
                </li>
                <li>
                    We are COMMITTED to doing everything we can to create a sterile environment to
                    protect you, and us, from airborne viruses.
                </li>
            </ul>
        </Article>
    </Fragment>
);

export default OurSafety;
